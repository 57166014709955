import React from 'react';
import Layout from '../../layouts/index';

import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import DeliveryInfos from '../../components/TilliUptrade/DeliveryInfos';
import ProgressBar from '../../components/TilliUptrade/ProgressBar';
import { Container, Content } from '../../components/home/v3/styledComponents';
import { StepAndButtonContainer, StepContainer } from '../../components/TilliUptrade/styledComponents';
import BenefitsBanner from '../../components/home/v3/BenefitsBanner';

import './style.css';

const DeliveryInfosPage = () => (
  <Layout routeSlug="DeliveryInfosPage">
    <Container>
      <MenuBar showGiftBanner={false} />
      <Content hasNoBanner>
        <StepAndButtonContainer>
          <StepContainer>
            <ProgressBar step={2} />
            <DeliveryInfos />
          </StepContainer>
        </StepAndButtonContainer>
      </Content>
      <BenefitsBanner backgroundColor="linen" color="navy" />
      <Footer />
    </Container>
  </Layout>
);

export default DeliveryInfosPage;
