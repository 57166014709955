import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import { pushToLayer } from '../../services/googleTagManager';
import routesMap from '../../Routes';

import withCustomerContext from '../../withCustomerContext';
import withUptradeContext from '../../withUptradeContext';
import { colors } from '../home/v3/styledComponents';
import { ComponentsContainer, MeetingInfosContainer, Title2 } from '../order/meetingInfosComponents';
import OrderSummaryContainer from './OrderSummaryContainer';
import callApi from '../../services/api';
import { getLocality } from '../../services/zipcode';
import { isEmailValid, isPhoneValid, getValidEmail } from '../../services/checkingFormat';
import LogoButton from '../order/LogoButton';
import Feedback from '../home/v3/Feedback';
import PopUpWaitTime from '../order/PopUpWaitTime';
import ContactInfos from '../order/ContactInfos';
import feedbacks from '../order/meetingInfosFeedbacks';
import {
  getInitState, getUpdatedFormInputsFromCustomer, getUpdatedFormInputsFromAddress, getUpdatedFormInputsBillingAddressFromAddress,
  checkEmailFormInputAndUpdateFormInputs, checkPhoneFormInputAndUpdateFormInputs, checkZipcodeFormInputAndUpdateFormInputs,
  getAddressPropsFromFormInputs, getCustomerProps, areAddressesIdentical, getBillingAddressPropsFromFormInputs, getEndPoint,
} from '../order/meetingInfosData';
import PasswordForgottenModal from '../MyAccount/PasswordForgottenModal';
import customerFeedbacks from '../home/v3/customerFeedbacks';
import { getOrder } from '../../services/uptradeOrderFormatting';
import { getZone } from '../../core/services/zipcode';

const SpanLink = styled.span`
  text-decoration: underline;
  color: ${colors.navy};
  cursor: pointer;
`;

class DeliveryInfos extends Component {
  constructor() {
    super();
    this.state = { ...getInitState() };
  }

  componentDidMount() {
    const { customerContext: { customer, initializeCustomerContext } } = this.props;
    if (customer !== undefined) {
      if (customer.isShadowAccount === true) {
        initializeCustomerContext();
      } else {
        this.setCustomer();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { customerContext: { customer } } = this.props;
    if (!prevProps.customerContext.customer && !!customer) this.setCustomer();
  }

  onSelectAddress = (address) => {
    const { formInputs } = this.state;
    this.setState({
      formInputs: { ...getUpdatedFormInputsFromAddress(formInputs, address) },
      selectedAddress: { ...address },
    });
  }

  getEmailValidityLabel = () => {
    const { formInputs: { email, firstname, lastname } } = this.state;
    const validEmail = getValidEmail(email, lastname, firstname);
    return (
      <span>
        Voulez-vous dire
        {' '}
        <SpanLink onClick={() => this.setEmail(validEmail)}>{validEmail}</SpanLink>
        {' '}
        :) ?
      </span>
    );
  };

  setEmail = (email) => {
    const { formInputs } = this.state;
    formInputs.email.value = email;
    formInputs.email.error = false;
    formInputs.email.hasError = false;
    formInputs.errorEmail.isActive = false;
    formInputs.errorEmailValidity.isActive = false;
    formInputs.errorEmailValidity.label = '';

    this.setState({ formInputs: { ...formInputs } }, this.checkEmail);
  }

  setCustomer = () => {
    const { customerContext: { customer } } = this.props;
    this.setIsAlreadyCustomer(true);
    this.setIsLoggedIn(true);
    this.displayAddressesList(true, () => {
      const { formInputs } = this.state;
      this.setFormInputs(getUpdatedFormInputsFromCustomer(formInputs, customer));
    });
  }

  setFormInputs = (formInputs, callback = () => {}) => {
    this.setState({ formInputs: { ...formInputs } }, callback);
  }

  setIsAlreadyCustomer = (isAlreadyCustomer) => {
    this.setState({ isAlreadyCustomer });
  }

  setIsLoggedIn = (isLoggedIn) => {
    this.setState({ isLoggedIn });
  }

  setFormInputValue = (event, listName) => {
    const { state } = this;
    const { target: { name, value } } = event;
    const newFormInputs = { ...state[listName] };
    if (name === 'zipcode') newFormInputs.locality.value = getLocality(value) ?? '';
    newFormInputs[name].value = value;
    if (listName === 'formInputsAlreadyCustomer' || name !== 'email') {
      this.setState({ [listName]: { ...newFormInputs } }, this.checkFormInputValue(name, listName, false));
    } else {
      this.setState({ [listName]: { ...newFormInputs } }, this.checkEmail);
    }
  }

  setIsAlreadyCustomerWhenEmailChecked = () => {
    const { formInputsAlreadyCustomer: formInputsAlreadyCustomerState, formInputs } = this.state;
    const formInputsAlreadyCustomer = { ...formInputsAlreadyCustomerState };
    formInputsAlreadyCustomer.email.value = formInputs.email.value;
    formInputsAlreadyCustomer.email.error = false;
    formInputsAlreadyCustomer.errorEmail.isActive = false;
    this.setState({
      isAlreadyCustomer: true,
      formInputsAlreadyCustomer: { ...formInputsAlreadyCustomer },
    });
  }

  setFormInputsForEmail = (isMoreActive = false) => {
    const { formInputs } = this.state;
    const newFormInputs = { ...formInputs };
    const callback = () => this.checkFormInputValue('email', 'formInputs', false);
    newFormInputs.email.more.isActive = isMoreActive;
    this.setFormInputs(newFormInputs, callback);
  }

  checkEmail = () => {
    const { formInputs } = this.state;
    const newFormInputs = { ...formInputs };
    const isValid = isEmailValid(newFormInputs.email.value);
    if (isValid) {
      callApi(`public/checkEmail/${formInputs.email.value}`, 'get').then(() => {
        this.setFormInputsForEmail(true);
      }).catch(() => {
        this.setFormInputsForEmail();
      });
    } else {
      this.setFormInputsForEmail();
    }
  }

  displayAddressesList = (mustSetAddress = false, callback = () => {}) => {
    const { customerContext: { customer } } = this.props;
    const addressesList = [{ ...customer.address, _id: 'initialAddress' }, ...customer.addresses].filter((address) => address.street);
    if (mustSetAddress && addressesList.length > 0) {
      const { formInputs } = this.state;
      this.setFormInputs(getUpdatedFormInputsFromAddress(formInputs, addressesList[0]));
    }
    this.setState({
      addressesList,
      selectedAddress: addressesList[0],
      isAddingNewAddress: !(addressesList && addressesList.length > 1),
    }, callback);
  }

  addAddress = () => {
    const { formInputs } = this.state;
    this.setIsAlreadyCustomer(true);
    const newFormInputs = getUpdatedFormInputsFromAddress(formInputs);
    this.setState({
      isAddingNewAddress: true,
      formInputs: { ...newFormInputs },
    });
  }

  login = ({ accessToken } = {}) => {
    const { formInputsAlreadyCustomer: { email, password } } = this.state;
    const request = accessToken
      ? { fbAccessToken: accessToken }
      : { email: email.value, password: password.value };
    callApi('login', 'post', request).then(({ customer }) => {
      const { formInputs } = this.state;
      const { customerContext: { setCustomer, setCustomerDiscountVouchers } } = this.props;
      const { _id, discountVouchers } = customer;

      const address = customer.address?.street ? customer.address : customer.addresses[0];
      pushToLayer({ event: 'Login', is_logged_in: true, user_id: _id });
      let newFormInputs = getUpdatedFormInputsFromCustomer(formInputs, customer);
      newFormInputs = getUpdatedFormInputsFromAddress(newFormInputs, address);

      setCustomer(customer);
      setCustomerDiscountVouchers(discountVouchers);
      this.displayAddressesList();
      this.setState({
        formInputs: { ...newFormInputs },
        isLoggedIn: true,
      }, () => Object.keys(newFormInputs).forEach((formInput) => !formInput.includes('error') && this.checkFormInputValue(formInput, 'formInputs')));
    }).catch(() => {
      const { formInputsAlreadyCustomer } = this.state;
      const newFormInputs = { ...formInputsAlreadyCustomer };
      newFormInputs.errorConnection.isActive = true;
      newFormInputs.email.error = undefined;
      this.setState({
        formInputsAlreadyCustomer: { ...newFormInputs },
        isLoggedIn: false,
      });
    });
  }

  checkIsDone = () => {
    const { formInputs, formInputsBillingAddress, areBillingAddressAndRdvAddressIdentical } = this.state;
    const isMainFormDone = Object.values(formInputs)
      .reduce((acc, { isRequired, hasError }) => acc && (!isRequired || hasError === false), true);
    const isBillingFormDone = areBillingAddressAndRdvAddressIdentical || Object.values(formInputsBillingAddress)
      .reduce((acc, { isRequired, hasError }) => acc && (!isRequired || hasError === false), true);
    return isMainFormDone && isBillingFormDone;
  }

  checkFormInputValue = (name, listName, mustDefineError = true) => {
    const { state } = this;
    let newFormInputs = { ...state[listName] };
    if (name === 'zipcode') {
      newFormInputs = checkZipcodeFormInputAndUpdateFormInputs(newFormInputs, mustDefineError, false);
    } else if (name === 'email') {
      const { isAlreadyCustomer } = this.state;
      newFormInputs = checkEmailFormInputAndUpdateFormInputs(newFormInputs, mustDefineError, isAlreadyCustomer, this.getEmailValidityLabel);
    } else if (name === 'phone') {
      newFormInputs = checkPhoneFormInputAndUpdateFormInputs(newFormInputs, mustDefineError);
    } else if (newFormInputs[name].isRequired) {
      if (mustDefineError) {
        newFormInputs[name].error = newFormInputs[name].value === '';
      }
      newFormInputs[name].hasError = newFormInputs[name].value === '';
    } else {
      newFormInputs[name].error = newFormInputs[name].value === '' ? undefined : false;
      newFormInputs[name].hasError = false;
    }
    this.setState({ [listName]: { ...newFormInputs } }, () => this.checkIsDone());
  }

  toggleAreBillingAddressAndRdvAddressIdentical = () => {
    const { customerContext: { customer } } = this.props;
    const { areBillingAddressAndRdvAddressIdentical, formInputsBillingAddress } = this.state;
    this.setState({
      areBillingAddressAndRdvAddressIdentical: !areBillingAddressAndRdvAddressIdentical,
      formInputsBillingAddress: getUpdatedFormInputsBillingAddressFromAddress(formInputsBillingAddress, customer?.billingAddress || undefined),
    });
  }

  renderContactInfos = () => {
    const {
      isAlreadyCustomer, isLoggedIn, areBillingAddressAndRdvAddressIdentical,
      formInputs, formInputsAlreadyCustomer, formInputsBillingAddress,
      selectedAddress, isAddingNewAddress, addressesList, isSubscribingToNewsletter,
    } = this.state;
    const { customerContext: { customer } } = this.props;

    const customerHasPhone = !!customer?.phone && isPhoneValid(customer.phone);
    const customerHasSubscribedToNewsletter = customer?.hasSubscribedToNewsletter || false;
    const inputList = (isAlreadyCustomer && !isLoggedIn) ? formInputsAlreadyCustomer : formInputs;
    return (
      <ContactInfos
        isAlreadyCustomer={isAlreadyCustomer}
        isLoggedIn={isLoggedIn}
        addingNewAddress={isAddingNewAddress}
        addressesList={addressesList}
        selectedAddress={selectedAddress}
        customerHasPhone={customerHasPhone}
        customerHasSubscribedToNewsletter={customerHasSubscribedToNewsletter}
        isSubscribingToNewsletter={isSubscribingToNewsletter}
        toggleSubscribeToNewsletter={() => this.setState({ isSubscribingToNewsletter: !isSubscribingToNewsletter })}
        formInputs={formInputs}
        inputList={inputList}
        setIsAlreadyCustomer={this.setIsAlreadyCustomer}
        login={this.login}
        onSelectAddress={this.onSelectAddress}
        addAddress={this.addAddress}
        checkFormInputValue={this.checkFormInputValue}
        setFormInputValue={this.setFormInputValue}
        setIsAlreadyCustomerWhenEmailChecked={this.setIsAlreadyCustomerWhenEmailChecked}
        showPasswordForgottenModal={() => this.setState({ isPasswordForgotten: true })}
        areBillingAddressAndRdvAddressIdentical={areBillingAddressAndRdvAddressIdentical}
        toggleAreBillingAddressAndRdvAddressIdentical={this.toggleAreBillingAddressAndRdvAddressIdentical}
        formInputsBillingAddress={formInputsBillingAddress}
      />
    );
  }

  renderButtons = () => {
    const { isAlreadyCustomer, isLoggedIn } = this.state;
    const customerFeedback = customerFeedbacks.ride;
    const isDone = this.checkIsDone();
    return ((!isAlreadyCustomer || (isAlreadyCustomer && isLoggedIn)) && (
      <>
        <ComponentsContainer marginBottom marginTop>
          <LogoButton onClick={() => navigate(routesMap.ProductsSelectorPage.url)} back noZoom>Choix du tissu</LogoButton>
          <LogoButton
            hideLogo
            backgroundColor={colors.navy}
            textColor={colors.white}
            mobileFirst
            isAvailable={isDone}
            onClick={this.goToNext}
          >
            Paiement
          </LogoButton>
        </ComponentsContainer>

        <ComponentsContainer>
          <Feedback isTransparent feedback={customerFeedback || feedbacks[0]} />
        </ComponentsContainer>
      </>
    )
    );
  }

  createCustomerRequest = () => {
    const {
      formInputs, selectedAddress, isAlreadyCustomer, isAddingNewAddress, isSubscribingToNewsletter,
    } = this.state;
    const { customerContext: { customer } } = this.props;
    const addressFromForm = getAddressPropsFromFormInputs(formInputs);
    const mustUseExistingAddress = !(!isAlreadyCustomer || isAddingNewAddress);
    if (!mustUseExistingAddress) {
      const { addresses = [] } = customer ?? {};
      const address = addresses.find((addressIt) => areAddressesIdentical(addressIt, addressFromForm)) || addressFromForm;
      return {
        ...getCustomerProps(formInputs),
        address: !getZone(address.zipcode) ? undefined : address,
        addressIt: address,
        hasSubscribedToNewsletter: isSubscribingToNewsletter,
      };
    }
    return { ...getCustomerProps(customer), address: selectedAddress };
  }

  createOrderRequest = (customer) => {
    const {
      uptradeContext: { partnerProducts, promoCode, discounts },
    } = this.props;
    const order = getOrder(partnerProducts, promoCode, discounts);

    return {
      ...order,
      promoCode: promoCode?.code,
      address: !customer.address ? customer.addressIt : customer?.address?._id, // eslint-disable-line no-underscore-dangle
      funnelInfo: {
        partnerProducts,
      },
    };
  }

  createRequest = () => {
    const { customerContext: { customer: customerProps } } = this.props;
    const {
      formInputs, isAddingNewAddress, isSubscribingToNewsletter, areBillingAddressAndRdvAddressIdentical, formInputsBillingAddress,
    } = this.state;
    const customer = this.createCustomerRequest();
    const order = this.createOrderRequest(customer);
    const customerHasSubscribedToNewsletter = customerProps?.hasSubscribedToNewsletter;
    const hasSubscribedToNewsletter = customerHasSubscribedToNewsletter || isSubscribingToNewsletter;
    const request = { order, hasSubscribedToNewsletter, phone: formInputs.phone.value || undefined };
    if (isAddingNewAddress && !order.address) {
      request.address = customer.address;
    } else {
      request.customer = customer;
    }
    if (!areBillingAddressAndRdvAddressIdentical) request.billingAddress = getBillingAddressPropsFromFormInputs(formInputsBillingAddress);
    return request;
  }

  goToNext = () => {
    const { isLoggedIn, isAddingNewAddress } = this.state;
    const { uptradeContext: { setRequestAndEndpoint } } = this.props;

    const request = this.createRequest();
    const endpoint = getEndPoint(isLoggedIn, isAddingNewAddress && !request.address, request.order.address);
    setRequestAndEndpoint(request, endpoint);
    navigate(routesMap.CreditCardInfosPage.url);
  }

  render() {
    const {
      isAlreadyCustomer, isLoggedIn, popUpWaitTimeIsOpen, isPasswordForgotten,
    } = this.state;
    return (
      <MeetingInfosContainer>
        {popUpWaitTimeIsOpen && (<PopUpWaitTime />)}
        <Title2>Adresse de livraison</Title2>
        <ComponentsContainer>
          {this.renderContactInfos()}
          {isAlreadyCustomer && !isLoggedIn && <Feedback isTransparent feedback={feedbacks[1]} />}
          {(!isAlreadyCustomer || (isAlreadyCustomer && isLoggedIn))
            && (
              <OrderSummaryContainer />
            )}
        </ComponentsContainer>
        {this.renderButtons()}
        {isPasswordForgotten && (
          <PasswordForgottenModal
            hide={() => this.setState({ isPasswordForgotten: false })}
          />
        )}
      </MeetingInfosContainer>
    );
  }
}

DeliveryInfos.propTypes = {
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      address: PropTypes.shape({}),
      addresses: PropTypes.arrayOf(PropTypes.shape({})),
      hasSubscribedToNewsletter: PropTypes.bool,
      isShadowAccount: PropTypes.bool,
      billingAddress: PropTypes.shape({}),
    }),
    initializeCustomerContext: PropTypes.func,
    setCustomer: PropTypes.func,
    setCustomerDiscountVouchers: PropTypes.func,
    setSelectedAccountCategory: PropTypes.func,
  }).isRequired,
  uptradeContext: PropTypes.shape({
    partnerProducts: PropTypes.arrayOf(PropTypes.shape({})),
    promoCode: PropTypes.shape({
      code: PropTypes.string,
    }),
    discounts: PropTypes.arrayOf(PropTypes.shape({})),
    setRequestAndEndpoint: PropTypes.func,
  }).isRequired,
};

export default withCustomerContext(withUptradeContext(DeliveryInfos));
