import styled from 'styled-components';
import ReactSelect from 'react-select';
import { mobileThresholdPixels, fontSizes, colors } from '../home/v3/styledComponents';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: center;
  margin-top: ${(props) => props.smallMarginTop ? '60' : '100'}px;
  ${({ marginBottom }) => marginBottom && 'margin-bottom: 60px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin: auto;
    margin-top: ${(props) => props.smallMarginTop ? '20' : '60'}px;
    align-items: center;
    ${(props) => props.mobileMarginTop && `margin-top: ${props.mobileMarginTop}px;`}
    width: calc(100vw - 40px);
  }
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  align-items: left;
  flex-wrap: wrap;
  margin-bottom: 50px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin: 0px 0px 30px 0px;
    width: 100%;
    ${({ noMarginBottomMobile }) => noMarginBottomMobile && 'margin-bottom: 0px;'}
  }
`;

export const TreeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin: auto;
    align-items: center;
    padding-right: 3px;
    padding-left: 3px;
  }
`;

export const Title = styled.section`
  font-family: Libre Baskerville;
  line-height: 48px;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  color: ${colors.navy};
  margin-bottom: ${(props) => props.marginBottom || '81'}px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 26px;
    line-height: 30px;
    margin: auto;
    margin-bottom: 40px;
    max-width: 290px;
  }
`;

export const Subtitle = styled.section`
  font-family: Libre Baskerville;
  line-height: 30px;
  font-weight: 600;
  font-size: 24px;
  text-align: ${(props) => props.left ? 'left' : 'center'};
  color: ${colors.navy};
  ${(props) => props.marginTop && 'margin-top: 79px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.flexStart ? 'flex-start' : 'center'};
  align-content: center;
  margin: ${(props) => props.small ? '50px' : '0px'};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`};
  flex-wrap: wrap;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${(props) => props.small ? '0px' : '30px 0px 0px 0px'};
    ${(props) => props.mobileNoMarginTop && 'margin-top: 0px;'};
    margin-bottom: ${(props) => props.marginBottom || '0px'};
    margin-left: ${(props) => props.marginLeft ? '62px' : '0px'};
    width: 100%;
  }
`;

export const SmallContent = styled(Content)`
  margin : 0px 50px 0px 50px;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}

  @media (max-width: ${mobileThresholdPixels}) {
    margin : 0;
    ${(props) => props.mobileNoMarginTop && 'margin-top: 0px;'}
    margin-bottom: ${(props) => props.marginBottom || '0px'};
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.center ? 'center' : 'flex-start'};
  flex-wrap: wrap;
  width: 976px;
  height: auto;
  margin: 60px 0px 0px 0px;
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}px;`}

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    margin: 30px 0px 0px 0px;
    ${(props) => props.mobileNoMarginTop && 'margin-top: 0px;'}
    justify-content: space-between;
  }
`;

export const Question = styled.p`
  display: flex:
  font-family: Roboto;
  font-weight: 500;
  line-height: 26px;
  font-size: 15px;
  color: ${colors.navy};
  margin-top: 0px;
  margin-left: 18px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 0px;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    padding-right: 3px;
    padding-left: 3px;
  }
`;

export const customStyles = {
  control: (base) => ({
    ...base,
    borderColor: colors.blueGrey,
    height: '40px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: '300',
    borderRadius: '3px',
    paddingLeft: '7px',
    boxShadow: 0,
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${colors.blueGrey}`,
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: colors.navy,
    fontWeight: '500',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: colors.blueGrey,
    '&:hover': {
      color: colors.navy,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: 'none',
  }),
};

export const setTheme = (theme) => (
  {
    ...theme,
    colors: {
      ...theme.colors,
      primary: colors.navy,
      primary25: colors.lightGrey,
      primary50: colors.lightGrey,
      neutral50: colors.darkBlueGrey,
    },
  }
);

export const Select = styled(ReactSelect)`
  width: 79px;
  margin-left: 18px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 0;
    width: 100%;
  }
`;

export const Label = styled.p`
  font-family: Roboto;
  font-weight: 500;
  font-size: 15px;
  text-align: left;
  margin-left: 18px;
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    margin-left: 15px;
  }
`;

export const AnswerCard = styled.button`
  margin: 0px 15px 10px 15px;
  height: 42px;
  width: 242px;
  background-color: ${(props) => props.isActive ? colors.navy : colors.white};
  color: ${(props) => props.isActive ? colors.white : colors.navy};
  ${(props) => props.isAvailable !== undefined
    && !props.isAvailable
    && `color: ${colors.blueGrey}; pointer-events: none;`}
  border-radius: 3px;
  border: 0.75px solid ${colors.blueGrey};
  box-sizing: border-box;
  outline: 0;
  cursor: pointer;
  transition: all 0.5s ease;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    height: 42px;
    margin: 2.5px 0px 2.5px 0px;
    ${(props) => props.extraMarginBottom && 'margin-bottom: 25px;'};
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

export { mobileThresholdPixels, fontSizes, colors };

export const createRange = (min, max) => {
  const range = [];
  for (let i = min; i < max + 1; i += 1) {
    range.push({ value: i, label: i.toString() });
  }
  return range;
};
