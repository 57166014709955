import React from 'react';
import PropTypes from 'prop-types';

import withUptradeContext from '../../withUptradeContext';
import withCustomerContext from '../../withCustomerContext';
import { getTotalPaid, getTotalPartnerProducts } from '../../core/services/orderPrice';
import callApi from '../../services/api';
import { pushToLayer } from '../../services/googleTagManager';
import OrderSummary from './OrderSummary';
import { getOrder } from '../../services/uptradeOrderFormatting';

class OrderSummaryContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      promoCode: {
        code: '',
      },
      hasPromoCode: false,
      errorPromoCode: false,
      isPromoCodeDisplayed: true,
    };
  }

  componentDidMount() {
    const { uptradeContext: { promoCode } } = this.props;
    if (promoCode?.code) {
      this.setHasPromoCode();
      this.checkPromoCode(promoCode.code);
      if (['CLACDESDOIGTS_17052019', 'TILLI_ONE_HOUR_ADVICE_29042020'].includes(promoCode.code)) this.setIsPromoCodeDisplayed(false);
    }
  }

  setIsPromoCodeDisplayed = (isPromoCodeDisplayed) => {
    this.setState({ isPromoCodeDisplayed });
  }

  setHasPromoCode = () => {
    this.setState({ hasPromoCode: true });
  }

  setPromoCode = (code) => {
    const { promoCode: promoCodeState } = this.state;
    let promoCode = { ...promoCodeState };
    if (typeof code === 'string') {
      promoCode.code = code;
    } else {
      promoCode = code;
    }
    this.setState({ promoCode });
  }

  getDiscountVouchers = () => {
    const {
      customerContext: { customer = {} },
    } = this.props;
    const { discountVouchers } = customer;

    return discountVouchers?.length > 0
      ? discountVouchers
        .filter((discountVoucher) => discountVoucher.unit === 'EUROS')
        .filter((discountVoucher) => !discountVoucher.usedByOrderId)
        .map((discountVoucher) => discountVoucher.value)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      : 0;
  }

  checkPromoCode = (promoCode) => {
    const { uptradeContext: { setPromoCode } } = this.props;
    callApi(`public/checkPromoCode/${promoCode}`)
      .then((res) => {
        pushToLayer({
          event: 'Partner product Funnel - Promo Code Added',
          promo_code: promoCode,
        });
        setPromoCode(res.promoCode);
        this.setPromoCode(res.promoCode);
        this.setState({ errorPromoCode: false });
      })
      .catch(() => {
        this.setState({
          promoCode: { code: '' },
          errorPromoCode: true,
        });
      });
  }

  render() {
    const {
      uptradeContext: {
        trackTotalPaid, partnerProducts, promoCode, discounts,
      },
    } = this.props;
    const { promoCode: promoCodeState } = this.state;
    const { hasPromoCode, errorPromoCode, isPromoCodeDisplayed } = this.state;
    const order = getOrder(partnerProducts, promoCode, discounts);
    const totalPaid = getTotalPaid(order);
    const totalPartnerProducts = getTotalPartnerProducts(order);
    return (
      <OrderSummary
        deliveryFee={order.deliveryFee}
        totalPartnerProducts={totalPartnerProducts}
        totalPaid={totalPaid}
        partnerProducts={order.partnerProducts}
        promoCode={promoCodeState}
        setPromoCode={this.setPromoCode}
        setHasPromoCode={this.setHasPromoCode}
        checkPromoCode={this.checkPromoCode}
        hasPromoCode={hasPromoCode}
        errorPromoCode={errorPromoCode}
        discountVouchersValue={this.getDiscountVouchers()}
        isPromoCodeDisplayed={isPromoCodeDisplayed}
        trackTotalPaid={trackTotalPaid}
      />
    );
  }
}

OrderSummaryContainer.propTypes = {
  uptradeContext: PropTypes.shape({
    partnerProducts: PropTypes.arrayOf(PropTypes.shape({})),
    trackTotalPaid: PropTypes.func,
    promoCode: PropTypes.shape({
      code: PropTypes.string,
      brand: PropTypes.string,
    }),
    discounts: PropTypes.arrayOf(PropTypes.shape({})),
    setPromoCode: PropTypes.func.isRequired,
  }).isRequired,
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({
      discountVouchers: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default withCustomerContext(withUptradeContext(OrderSummaryContainer));
