import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withOrderContext from '../../withOrderContext';
import { FormInput as FormInputV2, Button as ButtonV3 } from '../home/v3/styledComponents';
import { Subtitle } from '../order/orderStyledComponents';

import iconBasket from '../../assets/order/iconBasket.svg';
import iconChecked from '../../assets/order/iconChecked.svg';
import OrderSummaryPartnerProduct from './OrderSummaryPartnerProduct';

const Container = styled.div`
  width: 345px;
  box-sizing: border-box;
  height: auto;
  margin: 0px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 16px 30px;
  border-top: 8px solid ${({ theme }) => theme.colors.blueGrey};
  text-align: left;
  color: ${({ theme }) => theme.colors.navy};
  font-family: Roboto;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: calc(100vw - 40px);
    padding: 20px;
    margin: 45px auto;
  }
`;

const StyledSubtitle = styled(Subtitle)`
  line-height: 24px;
  font-size: 18px;
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SubContainer = styled.div`
  width: unset;
`;

const TotalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 20px 0px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ grey }) => grey && `color: ${({ theme }) => theme.colors.lightGrey3}; `}

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin: 10px 0px;
    font-size: 14px;
    line-height: 17px;
  }
`;

const Ul = styled.ul`
  padding-inline-start: 20px;
  margin: 0px;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    padding-inline-start: 40px;
  }
`;

const Li = styled.li`
  list-style-image : url(${(props) => props.icon});
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 20px;
  white-space: pre-wrap;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    margin-right: 0px;
    margin-left: -20px;
  }
`;

const Line = styled.div`
  border-width: 0.5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.lightGrey2};
  margin: ${(props) => props.marginTop || 20}px 0px ${(props) => props.noMarginBottom ? 0 : 20}px;
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin: ${(props) => props.marginTop || 10}px 0px 10px;
  }
`;

const PromoCode = styled.div`
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.lightGrey3};
  cursor: pointer;
`;

const PromoCodeForm = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Code = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.lightGrey4};
  margin-left: 5px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const Span = styled.span`
  color: ${({ theme }) => theme.colors.lightGrey4};
`;

const FlexContainer = styled.div`
  display: flex;
`;

const FormInput = styled(FormInputV2)`
  font-size: 14px;
  width: 142px;
  height: 38px;
  margin-top: 0px;
  border-color: ${({ theme }) => theme.colors.darkBlueGrey};
  padding-top: 0px;
  padding-bottom: 0px;
  outline: none;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    width: calc(60% - 15px);
  }
`;

const Button = styled(ButtonV3)`
  width: 111px;
  height: 40px;
  font-size: 18px;
  min-width: unset;
  min-height: unset;
  padding: 3px 0px 0px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 13px;
    margin: 0px;
    width: 35%;
  }
`;

const ErrorP = styled.p`
  font-family: Roboto;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.red};
  width: 87%;
  margin-top: 15px;
  margin-bottom: 0px;
  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 14px;
    width: 98%;
  }
`;

const Icon = styled.img`
  margin-right: 10px;
`;

const Price = styled.span`
  margin-left: 5px;
`;

const renderInfosContainer = () => (
  <Ul>
    <Li icon={iconChecked}>
      Il n’y a pas de découpe possible donc la longueur indiquée est celle vendue
    </Li>
    <Li icon={iconChecked}>
      Le prix de livraison varie en fonction du nombre de rouleaux et le métrage
    </Li>
    <Li icon={iconChecked}>
      Livraison sous 4 jours à 2 semaines pour les rouleaux
    </Li>
  </Ul>
);

const renderPromoCodeForm = (promoCode, setPromoCode, checkPromoCode, errorPromoCode) => (
  <div>
    <PromoCodeForm>
      <FormInput
        name="promoCode"
        value={promoCode.code || ''}
        type="text"
        placeholder="Code promo"
        onChange={(event) => setPromoCode(event.target.value)}
      />
      <Button onClick={() => checkPromoCode(promoCode.code)}>Valider</Button>
      {errorPromoCode && <ErrorP>Ce code promo n’est pas valide</ErrorP>}
    </PromoCodeForm>
  </div>
);

class OrderSummary extends React.Component {
  componentDidMount() {
    const { trackTotalPaid, totalPaid } = this.props;
    if (!Number.isNaN(totalPaid)) {
      trackTotalPaid(totalPaid);
    }
  }

  componentDidUpdate() {
    const { trackTotalPaid, totalPaid } = this.props;
    if (!Number.isNaN(totalPaid)) {
      trackTotalPaid(totalPaid);
    }
  }

  renderPartnerProducts = () => {
    const { partnerProducts } = this.props;
    return (
      <>
        {partnerProducts.map((partnerProduct) => (
          <OrderSummaryPartnerProduct
            key={partnerProduct.id}
            partnerProduct={partnerProduct}
          />
        ))}
        <Line />
      </>
    );
  }

  renderTotalRow = (label, price) => (
    <TotalRow>
      {label}
      <Price>{`${Math.round(price)} €`}</Price>
    </TotalRow>
  )

  render() {
    const {
      deliveryFee, totalPartnerProducts, totalPaid, promoCode, setPromoCode, hasPromoCode,
      setHasPromoCode, checkPromoCode, errorPromoCode, discountVouchersValue, isPromoCodeDisplayed,
    } = this.props;

    return (
      <Container>
        <StyledSubtitle left>
          <Icon src={iconBasket} alt="Panier" />
          Votre estimation
        </StyledSubtitle>
        <Line marginTop={14} noMarginBottom />
        <OrderContainer>
          <SubContainer>
            {this.renderPartnerProducts()}

            {this.renderTotalRow('Sous-Total', totalPartnerProducts)}
            {this.renderTotalRow('Frais de livraison', deliveryFee)}
            {(promoCode?.value > 0 && !Number.isNaN(promoCode.value)) && (
              <TotalRow>
                <FlexContainer>
                  Code promo
                  {isPromoCodeDisplayed && <Code>{promoCode.code}</Code>}
                </FlexContainer>
                <Span>{`- ${promoCode.value}${promoCode.unit === 'EUROS' ? '€' : '%'}`}</Span>
              </TotalRow>
            )}
            {discountVouchersValue > 0 && this.renderTotalRow('Crédits parrainage', `- ${discountVouchersValue}`)}
            <Line />

            {this.renderTotalRow('Total', totalPaid - discountVouchersValue > 0 ? totalPaid - discountVouchersValue : 0)}
          </SubContainer>

          <SubContainer>
            {renderInfosContainer()}
            {!hasPromoCode && (!promoCode || promoCode?.value === undefined) && (
              <Line marginBottom={10} />
            )}
            {!hasPromoCode && (!promoCode || promoCode?.value === undefined) && (
              <PromoCode onClick={setHasPromoCode}>J’ai un code promo</PromoCode>
            )}
            {hasPromoCode && (!promoCode || promoCode?.value === undefined) && (
              renderPromoCodeForm(promoCode, setPromoCode, checkPromoCode, errorPromoCode)
            )}
          </SubContainer>
        </OrderContainer>
      </Container>
    );
  }
}

OrderSummary.propTypes = {
  deliveryFee: PropTypes.number.isRequired,
  totalPartnerProducts: PropTypes.number.isRequired,
  totalPaid: PropTypes.number.isRequired,
  partnerProducts: PropTypes.arrayOf(PropTypes.shape({
  })),
  promoCode: PropTypes.shape({
    value: PropTypes.number,
    code: PropTypes.string,
    unit: PropTypes.string,
  }),
  setPromoCode: PropTypes.func.isRequired,
  setHasPromoCode: PropTypes.func.isRequired,
  checkPromoCode: PropTypes.func.isRequired,
  hasPromoCode: PropTypes.bool,
  errorPromoCode: PropTypes.bool,
  discountVouchersValue: PropTypes.number.isRequired,
  isPromoCodeDisplayed: PropTypes.bool,
  trackTotalPaid: PropTypes.func,
};

OrderSummary.defaultProps = {
  partnerProducts: [],
  promoCode: null,
  hasPromoCode: false,
  errorPromoCode: false,
  isPromoCodeDisplayed: true,
  trackTotalPaid() { },
};

export default withOrderContext(OrderSummary);
