import { getDeliveryFeesUptrade } from '../core/services/orderPrice';

export const getOrder = (partnerProducts, promoCode, discounts) => {
  const brand = !!(promoCode && promoCode.brand);
  const formattedPartnerProducts = partnerProducts.map((partnerProduct) => ({
    ...partnerProduct,
    lengthStr: `${partnerProduct.length} m`,
    brand,
    name: partnerProduct.description || partnerProduct.material,
  }));

  const order = {
    state: 'PARTNER_PRODUCT_WAITING_PAYMENT_CUSTOMER',
    process: 'PARTNER_PRODUCT',
    minOrderAmount: 0,
    partnerProducts: formattedPartnerProducts,
    discounts,
    funnelType: 'partner-product',
  };
  order.deliveryFee = getDeliveryFeesUptrade(order);
  return order;
};
